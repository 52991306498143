<template>
  <v-footer
    id="home-footer"
    color="black"
    dark
    min-height="72"
  >
    <v-container>
      <v-row justify="center">
        <v-col
          class="text-md-right text-sm-center text-center"
          cols="12"
          md="7"
        >
          {{ authTime }}
          <span class="ml-4"> {{ copyright }} </span>
        </v-col>
        <v-col
          class="text-md-left text-sm-center text-center"
          cols="12"
          md="5"
        >
          {{ beianId }}
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
  export default {
    name: 'HomeFooter',
    data () {
      return {
        authTime: this.$init.authTime,
        beianId: this.$init.beianId,
        copyright: this.$init.copyright,
        social: [
          'Facebook',
          'Twitter',
          'Instagram',
          'Linkedin',
        ],
      }
    },
  }
</script>

<style lang="sass">
  #home-footer a
    text-decoration: none
</style>
